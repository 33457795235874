// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".invite__wrapper[data-v-820444f4] {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n.invite__form[data-v-820444f4] {\n  border: 1px solid #DFE1E4;\n  border-radius: 6px;\n  padding: 30px;\n  min-width: 340px;\n  background-color: #fff;\n}\n.button[data-v-820444f4] {\n  width: 100%;\n  justify-content: center;\n}\n.field__label[data-v-820444f4] {\n  margin-bottom: 20px;\n}\n@media only screen and (max-width: 768px) {\n.invite__wrapper[data-v-820444f4] {\n    min-height: -webkit-fill-available;\n}\n}\n", ""]);
// Exports
module.exports = exports;
