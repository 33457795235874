<template>
  <div v-if="showForm" class="invite__wrapper">
    <form class="invite__form" @submit.prevent="register">
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.password.required && $v.password.minLength,
          'field__label--invalid':
            ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)
        }"
      >
        <div class="field__title field__title">Password</div>
        <input v-model.trim="$v.password.$model" class="field__input" type="password" placeholder="Password" />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.passwordConfirmation.sameAsPassword && $v.password.$dirty,
          'field__label--invalid': !$v.passwordConfirmation.sameAsPassword && $v.password.$dirty
        }"
      >
        <div class="field__title field__title">Confirm Password</div>
        <input
          v-model.trim="$v.passwordConfirmation.$model"
          class="field__input"
          type="password"
          placeholder="Password"
        />
      </label>
      <button type="submit" class="button button--fill">
        Register
      </button>
    </form>
  </div>
</template>

<script>
import {required, minLength, sameAs} from 'vuelidate/lib/validators'
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      token: null,
      password: '',
      passwordConfirmation: '',
      showForm: false
    }
  },
  async created() {
    const token = (this.token = this.$route.params.token)
    const successCheck = await this.checkInvite(token)
    if (successCheck) this.showForm = true
    else this.$router.push('/login')
  },
  methods: {
    ...mapActions({
      checkInvite: 'invite/check',
      registerByInvite: 'invite/register'
    }),
    async register() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const pass = {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation
      }

      const successCheck = await this.registerByInvite({pass, token: this.token})
      if (successCheck) this.$router.push('/login')
    }
  },
  validations: {
    password: {required, minLength: minLength(8)},
    passwordConfirmation: {sameAsPassword: sameAs('password')}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
