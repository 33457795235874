var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showForm
    ? _c("div", { staticClass: "invite__wrapper" }, [
        _c(
          "form",
          {
            staticClass: "invite__form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.register($event)
              }
            }
          },
          [
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.password.required && _vm.$v.password.minLength,
                  "field__label--invalid":
                    (_vm.$v.password.$dirty && !_vm.$v.password.required) ||
                    (_vm.$v.password.$dirty && !_vm.$v.password.minLength)
                }
              },
              [
                _c("div", { staticClass: "field__title field__title" }, [
                  _vm._v("Password")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.password.$model,
                      expression: "$v.password.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "password", placeholder: "Password" },
                  domProps: { value: _vm.$v.password.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.password,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _c(
              "label",
              {
                staticClass: "field__label",
                class: {
                  "field__label--filled":
                    _vm.$v.passwordConfirmation.sameAsPassword &&
                    _vm.$v.password.$dirty,
                  "field__label--invalid":
                    !_vm.$v.passwordConfirmation.sameAsPassword &&
                    _vm.$v.password.$dirty
                }
              },
              [
                _c("div", { staticClass: "field__title field__title" }, [
                  _vm._v("Confirm Password")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.passwordConfirmation.$model,
                      expression: "$v.passwordConfirmation.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "field__input",
                  attrs: { type: "password", placeholder: "Password" },
                  domProps: { value: _vm.$v.passwordConfirmation.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.passwordConfirmation,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _c(
              "button",
              { staticClass: "button button--fill", attrs: { type: "submit" } },
              [_vm._v(" Register ")]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }